import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Validators, FormGroup, FormControl } from "@angular/forms";

import { Constants } from "src/app/constants/constants";
import { TitleService } from "src/app/services/title.service";
import { AnnouncementsConfigurationsService } from "../announcements-configurations.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { SharedService } from "src/app/services/shared.service";
import { prepareToServer } from "src/app/utils/prepare-to-server";

import { AnnouncementsConfigurations } from "@zixi/models";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-announcement-configurations-form",
    templateUrl: "./announcement-configurations-form.component.html",
    providers: [TitleCasePipe]
})
export class AnnouncementConfigurationsFormComponent implements OnInit {
    announcementConfigurations: AnnouncementsConfigurations;
    announcementConfigurationsId: number;

    isLoading = true;
    action: string;
    isEdit = false;
    saving = false;
    announcementsConfigurationsListUrl = Constants.urls.announcements_configurations;

    customerFilterOptions = ["all", "aws_clusters_only"];
    severityOptions = ["info", "error", "warning"];
    form = new FormGroup({
        name: new FormControl<string>("", [Validators.required]),
        isActive: new FormControl<boolean>(true),
        startTime: new FormControl<string | Date>(null, [Validators.required]),
        endTime: new FormControl<string | Date>(null, [Validators.required]),
        isCritical: new FormControl<boolean>(false),
        isDismissible: new FormControl<boolean>(false),
        message: new FormControl<string>("", [Validators.required, Validators.minLength(2)]),
        moreInfoUrl: new FormControl<string>(""),
        severity: new FormControl<"info" | "error" | "warning">(null, [Validators.required]),
        isAdminOnly: new FormControl<boolean>(false),
        customerFilter: new FormControl<"all" | "aws_clusters_only">(null, [Validators.required])
    });

    isFormSubmitted = false;

    private announcementsConfigurationsService = inject(AnnouncementsConfigurationsService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private titleService = inject(TitleService);
    private titleCasePipe = inject(TitleCasePipe);
    private mixpanelService = inject(MixpanelService);
    private translateService = inject(TranslateService);
    private sharedService = inject(SharedService);

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.announcementConfigurationsId = parseInt(params.id, 10);
        this.action = params.action;
        if (this.announcementConfigurationsId) {
            this.isEdit = true;
            const announcementConfigurations = await firstValueFrom(
                this.announcementsConfigurationsService.refreshAnnouncementConfigurations(
                    this.announcementConfigurationsId,
                    true
                )
            );
            this.announcementConfigurations = Object.assign({}, announcementConfigurations);
        }
        if (this.action === "edit") this.isEdit = true;
        this.prepForm();
        this.isLoading = false;
    }

    prepForm() {
        if (this.isEdit && this.announcementConfigurations) {
            this.form.setValue({
                name: this.announcementConfigurations.name,
                isActive: this.announcementConfigurations.is_active,
                startTime: this.announcementConfigurations.start_time,
                endTime: this.announcementConfigurations.end_time,
                isCritical: this.announcementConfigurations.is_critical,
                isDismissible: this.announcementConfigurations.is_dismissible,
                message: this.announcementConfigurations.message,
                moreInfoUrl: this.announcementConfigurations.more_info_url,
                severity: this.announcementConfigurations.severity,
                isAdminOnly: this.announcementConfigurations.is_admin_only,
                customerFilter: this.announcementConfigurations.customer_filter
            });
        }

        // Set Title
        this.titleService.setTitle(
            this.translateService.instant("ANNOUNCEMENT_CONFIGURATIONS") +
                " - " +
                (this.action ? this.titleCasePipe.transform(this.action) : "New") +
                " " +
                (this.announcementConfigurations?.id ? this.announcementConfigurations.id : "")
        );
    }

    async onSubmit() {
        this.saving = true;
        this.isFormSubmitted = true;

        if (this.isFormSubmitted && this.form.invalid) {
            this.saving = false;
            return;
        }

        const announcementConfigurations = prepareToServer(this.form.getRawValue());
        let result: boolean | AnnouncementsConfigurations = false;

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(
                announcementConfigurations,
                this.announcementConfigurations,
                [],
                {}
            );
            result = await this.announcementsConfigurationsService.updateAnnouncementConfigurations(
                this.announcementConfigurationsId,
                changedData
            );
        } else {
            result = await this.announcementsConfigurationsService.createAnnouncementConfigurations(
                announcementConfigurations
            );
            if (result) {
                this.mixpanelService.sendEvent("create announcement configurations");
            }
        }

        if (result) this.router.navigate([this.announcementsConfigurationsListUrl, result.id]);
        this.saving = false;
    }
}
